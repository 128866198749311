/**
 * Const with StaticQuery and param element
 * Contact infos component
 * Display contact information
 */
/* Import section */
import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { IconContext } from "react-icons"
import { BsPhone, BsEnvelope, BsClock } from "react-icons/bs"
import "../styles/components/contactinfos.scss"

/* Declaration function with StaticQuery */
const Contactinfos = ({ classNameInfos }) => (
  <StaticQuery
    query={graphql`
      query {
        wpPage(slug: {eq: "options-website"}) {
          acfPageOptions {
            businessAdress1
            businessAdress2
            businessAdress3
            businessEmail
            businessHours
            businessName
            businessPhone
            supportEmail
            supportPhone
          }
        }
      }
    `}
    render={data => (
      <ul className="list-infos-contact">
        <li>
          <span className="span-icon">
            <IconContext.Provider value={{ className: "icon-phone icon-svg" }}>
              <BsPhone />
            </IconContext.Provider>
            {classNameInfos === "adress" ? (
              <span><a href={`tel:+${data.wpPage.acfPageOptions.businessPhone}`} target="_blank" rel="noreferrer">{data.wpPage.acfPageOptions.businessPhone}</a></span>
            ) : (
              <span><a href={`tel:+${data.wpPage.acfPageOptions.supportPhone}`} target="_blank" rel="noreferrer">{data.wpPage.acfPageOptions.supportPhone}</a></span>
            )}
            </span>
        </li>
        <li>
          <span className="span-icon">
            <IconContext.Provider value={{ className: "icon-enveloppe icon-svg" }}>
              <BsEnvelope />
            </IconContext.Provider>
            {classNameInfos === "adress" ? (
              <span><a href={`mailto:${data.wpPage.acfPageOptions.businessEmail}`} target="_blank" rel="noreferrer">{data.wpPage.acfPageOptions.businessEmail}</a></span>
            ) : (
              <span><a href={`mailto:${data.wpPage.acfPageOptions.supportEmail}`} target="_blank" rel="noreferrer">{data.wpPage.acfPageOptions.supportEmail}</a></span>
            )}

          </span>
        </li>
        <li>
          <span className={`span-icon ${classNameInfos}`}>
            <IconContext.Provider value={{ className: "icon-clock icon-svg" }}>
              <BsClock />
            </IconContext.Provider>
            {classNameInfos === "adress" ? (
              <span>{data.wpPage.acfPageOptions.businessAdress1} <br/>
              {data.wpPage.acfPageOptions.businessAdress2}<br/>
              {data.wpPage.acfPageOptions.businessAdress3}</span>
            ) : (
              <span>{data.wpPage.acfPageOptions.businessHours}</span>
            )}
          </span>
        </li>
      </ul>
    )}
  />
)

/* Export function */
export default Contactinfos
