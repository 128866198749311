/**
 * Contact us page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Contactinfos from "../components/contactinfos"
import Herotype2 from "../components/herotype2"
import Hubspot from "../components/hubspotform"
import Layout from "../components/layout"
import Mediasocial from "../components/mediasocial"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/pages/contact-us.scss"


/* Page function declaration */
const Contactuspage = ({data}) => {

  //Const data
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageContact

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Hero section */}
      <section className="section-hero-contact top-section">
        <Herotype2 h1title={dataAcf.titleHeroContact} textIntro={dataAcf.textHeroContact} colorBar="white" />
      </section>

      {/*contact details section */}
      <section className="section-details-contact">
        <div className="wrap-intro-details-contact wrap-maincontent">
          <div className="box-info">
            <Titledecorative title={dataAcf.titleInformationContact} />
            <Contactinfos classNameInfos="adress" />
          </div>
          <div className="box-info">
            <Titledecorative title={dataAcf.titleMediaSocialContact} />
            <Mediasocial />
          </div>
        </div>
      </section>

      {/*contact request form section */}
      <section className="section-request-contact">
        <div className="wrap-maincontent wrap-form">
          <h2><span>{dataAcf.titleRequestContact}</span></h2>
          <div className="text-form" dangerouslySetInnerHTML={{ __html:dataAcf.textRequestContact}} />
          <Hubspot formIdentifier={dataAcf.formIdHubspotContact} />
        </div>
      </section>
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "contact-us"}) {
      acfPageContact {
        formIdHubspotContact
        textHeroContact
        textRequestContact
        titleHeroContact
        titleInformationContact
        titleMediaSocialContact
        titleRequestContact
      }
      title
      seo {
        title
        metaDesc
      }
    }
  }
`
/* Export page informations */
export default Contactuspage
